import React, { useEffect, useMemo, useState } from 'react'
import { Link, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import Table from '../../../components/Table/Table'
import ledgerApi, { fetchMerchant } from '../../../services/ledgerApi'
import MerchantStatuses from '../../../components/Merchant/MerchantStatuses'
import gatewayApi from '../../../services/gatewayApi'
import { groupBy } from '../../../helpers/utils'
import ToggleInput from '../../../components/Form/Inputs/ToggleInput'
import { useTranslation } from 'react-i18next'
import ProcessorModal from '../../../components/Modal/ProcessorModal'
import { useFlags } from 'flagsmith/react'
import authenticatorApi from '../../../services/authenticatorApi'
import Filters from '../../../components/Filters/Filters'
import { useAuth } from '../../../contexts/AuthContext'
import { useQueryClient } from '@tanstack/react-query'
import { fetchTransactionsList } from '../../../services/gatewayApi'

const initialFilters = {
  type: ['charge'],
  status: [],
  source: []
}

export default function ListMerchants() {
  const [isLoading, setIsLoading] = useState(true)
  const [originalMerchants, setOriginalMerchants] = useState([])
  const [filteredMerchants, setFilteredMerchants] = useState([])
  const [isFilterLoading, setIsFilterLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const auth = useAuth()
  const [input, setInput] = useState('')
  const flags = useFlags(['change_processor_merchants'])
  const [reload, setReload] = useState(true)
  const location = useLocation()
  const [userEmail, setUserEmail] = useState('')
  const [isUserEmailLoaded, setIsUserEmailLoaded] = useState(false)
  const { t } = useTranslation()
  const [data, setData] = useState({
    merchants: [],
    pagination: {
      skip: 0,
      limit: 10,
      total_items: 0,
      current_page: 1,
      total_pages: 0
    },
    meta: {}
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchParams, setSearchParams] = useState({ limit: 600 })

  const [searchQueryValues, setSearchQueryValues] = useState({
    name: '',
    uuid: ''
  })
  const discoverField = (value) => {
    const uuid = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i')

    if (value.match(uuid)) {
      return 'uuid'
    }

    return 'name'
  }

  const searchQuery = Object.values(searchQueryValues).filter(Boolean)[0] || ''
  const handleFilterChange = async (filters) => {
    setSelectedFilters(handleSearchQueryChange)
  }
  const handleSearchQueryChange = (event) => {
    const value = event.target.value
    const field = discoverField(value)

    setSearchQueryValues((prevState) => ({
      ...prevState,
      [field]: value
    }))

    if (value === '') {
      setSearchQueryValues({ name: '', uuid: '' })
      setData((prevData) => ({
        ...prevData,
        merchants: originalMerchants.slice(0, prevData.pagination.limit),
        pagination: {
          ...prevData.pagination,
          total_items: originalMerchants.length,
          total_pages: Math.ceil(originalMerchants.length / prevData.pagination.limit),
          skip: 0
        }
      }))
      return
    }

    const merchantsFiltered = originalMerchants.filter((merchant) => {
      if (field === 'uuid') {
        return merchant.id.includes(value)
      }
      if (field === 'name') {
        return merchant.name.toLowerCase().includes(value.toLowerCase())
      }
      return false
    })

    setData((prevData) => ({
      ...prevData,
      merchants: merchantsFiltered.slice(0, prevData.pagination?.limit || 10),
      pagination: {
        ...prevData.pagination,
        total_items: merchantsFiltered.length,
        total_pages: Math.ceil(merchantsFiltered.length / prevData.pagination?.limit || 10),
        skip: 0
      }
    }))
  }

  useEffect(() => {
    const activeQuery = Object.values(searchQueryValues).filter(Boolean)[0] || ''

    if (!activeQuery) {
      setFilteredMerchants(originalMerchants)
      return
    }

    const merchantsFiltered = originalMerchants.filter((merchant) => {
      const { uuid, name } = searchQueryValues
      if (uuid) {
        return merchant.id.toLowerCase().includes(uuid.toLowerCase())
      }
      if (name) {
        return merchant.name.toLowerCase().includes(name.toLowerCase())
      }
      return false
    })

    setFilteredMerchants(merchantsFiltered)
  }, [originalMerchants, searchQuery])

  const filters = [
    {
      id: 'status',
      title: t('TransactionsSidePainelStatus'),
      options: [
        { id: 'success', title: t('TransactionsFiltersTypeSuccess') },
        { id: 'failed', title: t('TransactionsFiltersTypeFailed') },
        { id: 'pending', title: t('TransactionsFiltersTypePending') }
      ]
    },
    selectedFilters.type.length === 1 &&
      auth.user.role !== 'merchant_employee' &&
      selectedFilters.type.includes('payment') && {
        id: 'source',
        title: t('TransactionsSidePainelPaymentType'),
        options: [
          { id: 'player', title: t('TransactionsFiltersPaymentTypePlayers') },
          { id: 'vendor', title: t('TransactionsFiltersPaymentTypeVendors') },
          { id: 'settlement', title: t('TransactionsFiltersPaymentTypeSettlement') }
        ]
      }
  ].filter(Boolean)

  useEffect(() => {
    authenticatorApi
      .get('/app/merchant/users/me')
      .then((response) => {
        setUserEmail(response.data.user.email)
        setIsUserEmailLoaded(true)
      })
      .catch((error) => {
        console.log('Error fetching user info:', error)
        setIsUserEmailLoaded(true)
      })
  }, [])
  const processorMerchants = flags.change_processor_merchants.value || []

  const isChangeProcessorAvailable = isUserEmailLoaded && processorMerchants.includes(userEmail)

  useEffect(() => {
    // const { skip, limit } = data.pagination
    setIsLoading(true)
    ledgerApi
      .get('/v1/admin/merchants', { params: searchParams })
      .then((response) => {
        const responseData = response.data
        setOriginalMerchants(responseData.merchants)
        setData({
          merchants: responseData.merchants,
          pagination: {
            skip: responseData.pagination.skip,
            limit: responseData.pagination.page_size,
            total_items: responseData.pagination.total_items,
            current_page: responseData.pagination.current_page,
            total_pages: responseData.pagination.total_pages
          }
        })
        setIsLoading(false)
      })
      .catch((e) => {
        console.error('Error fetching merchants:', e)
        setIsLoading(false)
      })
  }, [searchParams])

  useEffect(() => {
    setReload(location.state?.reload)
  }, [location.state?.reload])

  const columns = [
    {
      title: 'Merchant',
      render: (merchant) => (
        <Link to={`/admin/merchant/${merchant.id}`}>
          <span className="mb-1 block font-semibold">{merchant.name}</span>
          <span className="text-xs text-gray-500">{merchant.id}</span>
        </Link>
      )
    },
    {
      title: 'Platform',
      render: (merchant) => <span>{merchant.platform || 'N/A'}</span>
    },
    {
      title: 'Status',
      node: 'status',
      render: (merchant) => <MerchantStatuses merchant={merchant} />
    },
    {
      render: (merchant) => (
        <Button as={<Link to={`/admin/merchant/${merchant.id}`} />} size="xs" variant="secondary">
          {t('AdmimViewButton')}
        </Button>
      )
    }
  ]

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const paginatedMerchants = useMemo(() => {
    const start = data.skip
    const end = start + data.limit
    return filteredMerchants.slice(start, end)
  }, [filteredMerchants, data.pagination])

  const toggleIsRegulated = (isChecked) => {
    const p = { limit: 600, q: '' }
    if (isChecked) {
      p.q = 'is_regulated'
    }
    console.log(setSearchParams(p))
    setSearchParams(p)
  }

  return (
    <div>
      <Outlet />

      <PageHeader
        title={t('AdmimMerchant')}
        subtitle={t('AdmimMerchantSubtitles')}
        button={
          <>
            <div className="my-auto">
              <ToggleInput optionLeft="Não Regulado" optionRight="Regulado" onChange={toggleIsRegulated} />
            </div>
            <Button as={<Link />} to="/admin/merchants/new">
              {t('AdmimMerchantButton')}
            </Button>
            {isChangeProcessorAvailable && (
              <Button variant="white" onClick={openModal}>
                {t('AdmimMerchantProcessorButton')}
              </Button>
            )}
          </>
        }
      />

      <Filters
        filters={filters}
        selectedFilters={selectedFilters}
        onChangeFilters={handleFilterChange}
        searchQuery={Object.values(searchQueryValues).filter(Boolean)[0] || ''}
        onChangeSearchQuery={handleSearchQueryChange}
        searchQueryPlaceholder={t('MerchantInputSearch')}
        onReset={() => {
          setSearchQueryValues({ name: '', uuid: '' })
          setFilteredMerchants(originalMerchants)
        }}
      />

      <Main className="pt-4">
        <Table isLoading={isLoading || isFilterLoading} columns={columns} rows={data.merchants} />
      </Main>

      <ProcessorModal
        title={t('ProcessorModalTitle')}
        subtitle={t('ProcessorModalSubtitle')}
        bodyText={t('ProcessorModalBodyText')}
        open={isModalOpen}
        onClose={closeModal}
      />
    </div>
  )
}
